export const AUTH_URL = "http://47.91.109.252:9000/authservice";
//export const AUTH_URL = "http://localhost:9000/authservice";
export const USER_URL = "http://47.91.109.252:8081/userservice";
//export const USER_URL = "http://localhost:8081/userservice";
export const COMM_URL = "http://47.91.109.252:8082/commservice";
export const ENV = "dev";



//export const AUTH_URL="https://authservices.acesinnovate.com/authservice";
//export const USER_URL="https://devservices.acesinnovate.com/userservice";
//export const COMM_URL="https://commservices.acesinnovate.com/commservice";
//export const ENV="prod";

export const ADD_EMPLOYEE = AUTH_URL + "/user/v0/create";
export const UPDATE_EMPLOYEE = AUTH_URL + "/user/v0/update";
export const GET_EMPLOYEE = USER_URL + "/user/v0/get";
export const GET_EMPLOYEE_SEARCH = USER_URL + "/user/v0/get/name";
export const GET_EMPLOYEE_USERNAME_SEARCH = USER_URL + "/user/v0/get/uname";
export const DELETE_EMPLOYEE = AUTH_URL + "/user/v0/delete";
export const SEND_EMAIL = COMM_URL + "/email/v0/publish";
export const SEND_NOTIFICATON = COMM_URL + "/push/v0/publish";
export const ATTENDANCE_VIEW = USER_URL + "/attendance/v0/attendance/view";
export const PROFILE_STATUS = USER_URL + "/user/v0/get/profile/status";
export const IQMA_EXPIRY = USER_URL + "/user/v0/get/iquama/expiry";
export const LOCATION = USER_URL + "/location/v0/dashboard/get";
export const ATTENDANCE_REPORT = USER_URL + "/attendance/v0/attendance/report";
export const LOCATION_DATA = USER_URL + "/location/v0/get";
export const GET_NAMES = USER_URL + "/user/v0/get/name";
export const GET_LOCATION = USER_URL + "/location/v0/get/location";
export const GET_USER_DETAILS = USER_URL + "/user/v0/get/userdetails";
export const UPDATE_EMPLOYEE_DATA = USER_URL + "/user/v0/update";
export const NOTIFICATIONS = COMM_URL + "/push/v0/";
export const LOGIN_URL = AUTH_URL + "/auth/v0/login";
export const GENERATE_OTP = AUTH_URL + "/auth/v0/otp";
export const UPDATE_PASSWORD = AUTH_URL + "/auth/v0/update";
export const OD_OC_UPDATE = USER_URL + "/roster/v0/createSchedule";
export const GET_OD_OC = USER_URL + "/roster/v0/getAllSchedules";
// export const GET_META_DATA = USER_URL + "/config/v0/list/ZONE,MC";
export const GET_META_DATA = USER_URL + "/config/v0/getAllData";
export const GET_AUTH_USER_DETAILS = AUTH_URL + "/user/v0/get";
export const UPDATE_AUTH_USER_DETAILS = AUTH_URL + "/user/v0/update";
export const GET_EMP_META_DATA = USER_URL + "/config/v0/list/REGION,SECTION,JOB_TITLE,DEPARTMENT";
export const GET_REPORT_MANAGER = AUTH_URL + "/user/v0/reporting"

// ExpenseSheet uls
export const GET_CASHREQUEST_DATA = USER_URL + "/cashRequest/v0/getAllAssigned"
export const GET_SINGLEUSER_DATA = USER_URL + "/cashRequest/v0/viewRequestDetails";
export const CREATE_CASH_REQUEST = USER_URL + "/cashRequest/v0/createCashRequest";
export const UPDATE_TRACKER_STATUS = USER_URL + "/cashRequest/v0/updateTrackerStatus";

//Report Tab 
export const GET_All_EMP_DATA = USER_URL + "/report/v0/expense"

//Schedule
export const CREATE_SCHEDULE = USER_URL + "/schedule/v0";
export const GET_SCHEDULE_SEARCH_BY_NAME = USER_URL + "/schedule/v0/name";
export const DELETE_SCHEDULE = USER_URL + "/schedule/v0";
export const GET_ALL_SCHEDULES = USER_URL + "/schedule/v0";

//roster
export const UPDATE_ROSTER = USER_URL + "/roster/v1";
export const CREATE_ROSTER = USER_URL + "/roster/v1";
export const GET_ALL_ROSTER = USER_URL + "/roster/v1";
export const DELETE_ROSTER = USER_URL + "/roster/v1";
export const POST_ROSTER = USER_URL + "/roster/v1/post";
export const GET_SCHEDULE_NAMES = USER_URL + "/schedule/v0/names"

//Meta Data
export const GET_ALL_META_DATA = USER_URL + "/config/v0/location";
export const SEARCH_EMP_NAMES = USER_URL + "/user/v0/get/userNames";

//Download Attendance Data 
export const DOWNLOAD_DATA = USER_URL + "/attendance/v0/attendance/report"
export const DOWNLOAD_EXPENSE_SHEET = USER_URL + "/cashRequest/v0/getExpenseReport"
export const DOWNLOAD_EXPENSE_DETAILS_SHEET = USER_URL + "/cashRequest/v0/expenseSheetDetailsReport"
export const DOWNLOAD_EXPENSE_SHEET_REPORT_TWO = USER_URL + "/cashRequest/v0/financeReport"
//
export const PREVIEW_DATA = USER_URL + "/cashRequest/v0/get/previewFile"
export const SEARCH_EMP_NAMES_ROSTER = USER_URL + "/user/v0/search/name";

//Settings
export const GET_TABLE_DATA = USER_URL + "/config/v0/get/name";
export const POST_FORM_DATA = USER_URL + "/config/v0/create";


//Add emp section data api
export const GET_EMP_SECTION_META_DATA = USER_URL + "/config/v0/get/parent/SECTION"