import React from 'react';
import Employees from './Employees'
import Attendance from './Attendance'
import { BrowserRouter, Route, useHistory } from 'react-router-dom'

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import { NavLink } from 'react-router-dom';

import Logout from "../images/logout.svg";
import Dashboard from './Dashboard';
import Location from './Location';
import Notification from './Notification';
import ProtectedRoute from '../ProtectedRoute';
import ExpenseSheet from './ExpenseSheet/ExpenseSheet';
import Policy from './Policy';
import ScheduleMainPage from './Schedule/ScheduleMainPage';
import RosterSchedule from './RosterTab/RosterSchedule';
import { AiFillSetting } from 'react-icons/ai'
import SettingsPage from '../components/Settings/UpdateProfile'


function NavigationBar() {
  const history = useHistory();
  const myDate = new Date();
  const local = myDate.getTime();
  const offset = myDate.getTimezoneOffset() * (60 * 1000);
  const utc = new Date(local + offset);
  var riyadh = new Date(utc.getTime() + (3 * 60 * 60 * 1000));
  var hrs = riyadh.getHours();
  const wish = hrs < 12 ? "Good Morning" : hrs >= 12 && hrs <= 17 ? "Good Afternoon" : "Good Evening";
  const isHr = sessionStorage.getItem("isHr");

  return (
    <div>
      <BrowserRouter>
        <div className="container">
          <div className="sideNav">
            <div className="logo">
              <div className="arch">
                <span className="redline" />
                <span className="redline1" />
                <span className="redline2" />
                <span className="redline3" />
              </div>
              <h1 className="name">ACES</h1>
              <div className="icon">
                <input type="checkbox" id="menu_checkbox" />
                <label htmlFor="menu_checkbox">
                  <div />
                  <div />
                  <div />
                </label>
              </div>
            </div>
            <nav>
              <ul>

                <NavLink className="li" to="dashboard"> <i className="fa-solid fa-gauge" />Dashboard</NavLink>
                <NavLink className="li" to="attendance" > <i className="fa-solid fa-calendar" />Attendance</NavLink>
                <NavLink className="li" to="employee" onClick={() => window.location.href = "/employee"}><i className="fa-solid fa-user" />Employee</NavLink>
                <NavLink className="li" to="location" > <i className="fa-solid fa-location-dot" />Location</NavLink>
                <NavLink className="li" to="expensesheet" onClick={() => window.location.href = "/expensesheet"}><i class="fa-solid fa-clipboard-list"></i>Expense Sheet</NavLink>
                <NavLink className="li" to="notification" ><i className="fa-regular fa-bell" />Notification</NavLink>
                <NavLink className="li" to="schedule" onClick={() => window.location.href = "/schedule"}><i className="fa-solid fa-calendar-week" /> Schedule</NavLink>
                <NavLink className="li" to="roster"><i className="fa-sharp fa-regular fa-clock" />Roster</NavLink>
                {isHr === 'true' && <NavLink className="li" to="settings" ><i class="fa fa-cog" aria-hidden="true"></i>Settings</NavLink>}
              </ul>
            </nav>
          </div>
          <div className="topProfile">
            <div className="topcontent">
              <div className="toplogo"><img src={sessionStorage.getItem('image')} alt="img" /></div>
              <div className="content"><h4><span style={{ fontWeight: 'bold' }}>&nbsp;&nbsp;&nbsp;&nbsp;{wish}, {sessionStorage.getItem('name')} </span><i className="fa-solid fa-angle-down" /></h4></div>

              <span className="logout"><img src={Logout} alt="logout" onClick={(event) => {
                if (!event.defaultPrevented) {
                  sessionStorage.clear();
                  history.push("/");
                }
              }} className="logoutImg" /></span>
              {/* {isHr === 'true' && <NavLink to="/settings">
                <span style={{ position: 'absolute', right: '30px', cursor: 'pointer', color: 'black' }} className='bg-white rounded-circle p-1' onClick={() => {
                  history.push('/settings')
                }}>
                  <AiFillSetting size={25} />
                </span>
              </NavLink>} */}
            </div>
            <div class="lowercontent">Login at {sessionStorage.getItem('date')}</div>

            <ProtectedRoute path="/dashboard" component={Dashboard} />
            <Route path="/privacy-policy" component={<Policy />} />
            <ProtectedRoute path="/employee" component={Employees} />
            <ProtectedRoute path="/attendance" component={Attendance} />
            <ProtectedRoute path="/location" component={Location} />
            <ProtectedRoute path="/expensesheet" component={ExpenseSheet} />
            <ProtectedRoute path="/notification" component={Notification} />
            <ProtectedRoute path="/schedule" component={ScheduleMainPage} />
            <ProtectedRoute path="/roster" component={RosterSchedule} />
            <ProtectedRoute path="/settings" component={SettingsPage} />




          </div>
        </div>
      </BrowserRouter>
    </div>
  )
}

export default NavigationBar