import React from 'react';

const StepProgress = ({ steps, currentStep, progressColor, mapOfResponsesAndUsers, Status }) => {
  const calculateProgress = () => {
    return ((currentStep - 1) / (steps.length - 1)) * 100;
  };

  return (
    <div className="step-progress">
      <div className="progress-bar-container">
        <div
          className="progress-bar-new"
          style={{
            width: `${calculateProgress()}%`,
            backgroundColor: progressColor,
          }}
        ></div>
        <div className="dots-container">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`dot ${index + 1 < currentStep ? 'completed' : ''} ${
                index + 1 === currentStep ? 'active' : ''
              } ${
                (mapOfResponsesAndUsers?.Role_Report_Manager?.actionTaken === 'REJECTED' ||
                  mapOfResponsesAndUsers?.Role_Finance_One?.actionTaken === 'REJECTED' ||
                  mapOfResponsesAndUsers?.Role_Finance_Two?.actionTaken === 'REJECTED') &&
                'rejectTicketRed'
              }`}
            ></div>
          ))}
        </div>
      </div>

      <div className="step-circles align-items-start">
        {steps.map((step, index) => {
          let label = step;
          if (index + 1 === 2 && mapOfResponsesAndUsers?.Role_Report_Manager) {
            if (mapOfResponsesAndUsers.Role_Report_Manager.actionTaken === 'APPROVED') {
              label = (
                <span className='d-flex flex-column'>
                  APPROVED BY Manager {mapOfResponsesAndUsers.Role_Report_Manager.userFullName && ':'} <strong>{mapOfResponsesAndUsers.Role_Report_Manager.userFullName}</strong>
                </span>
              );
            } else if (mapOfResponsesAndUsers.Role_Report_Manager.actionTaken === 'PENDING') {
              label = (
                <span className='d-flex flex-column'>
                  PENDING With Manager {mapOfResponsesAndUsers.Role_Report_Manager.userFullName && ':'} <strong>{mapOfResponsesAndUsers.Role_Report_Manager.userFullName}</strong>
                </span>
              );
            } else {
              label = (
                <span className='d-flex flex-column'>
                  REJECTED BY Manager {mapOfResponsesAndUsers.Role_Report_Manager.userFullName && ':'} <strong>{mapOfResponsesAndUsers.Role_Report_Manager.userFullName}</strong>
                </span>
              );
            }
          } else if (index + 1 === 3 && mapOfResponsesAndUsers?.Role_Finance_One) {
            if (mapOfResponsesAndUsers.Role_Finance_One.actionTaken === 'APPROVED') {
              label = (
                <span className='d-flex flex-column'>
                  APPROVED BY Finance1 {mapOfResponsesAndUsers.Role_Finance_One.userFullName && ':'} <strong>{mapOfResponsesAndUsers.Role_Finance_One.userFullName}</strong>
                </span>
              );
            } else if (mapOfResponsesAndUsers.Role_Finance_One.actionTaken === 'PENDING') {
              label = (
                <span className='d-flex flex-column'>
                  PENDING With Finance1 {mapOfResponsesAndUsers.Role_Finance_One.userFullName && ':'} <strong>{mapOfResponsesAndUsers.Role_Finance_One.userFullName}</strong>
                </span>
              );
            } else {
              label = (
                <span className='d-flex flex-column'>
                 REJECTED BY Finance1 {mapOfResponsesAndUsers.Role_Finance_One.userFullName && ':'} <strong>{mapOfResponsesAndUsers.Role_Finance_One.userFullName}</strong>
                </span>
              );
            }
          } else if (index + 1 === 4 && mapOfResponsesAndUsers?.Role_Finance_Two) {
            if (mapOfResponsesAndUsers.Role_Finance_Two.actionTaken === 'APPROVED') {
              label = (
                <span className='d-flex flex-column'>
                APPROVED BY Finance2 {mapOfResponsesAndUsers.Role_Finance_Two.userFullName && ':'} <strong>{mapOfResponsesAndUsers.Role_Finance_Two.userFullName}</strong>
                </span>
              );
            } else if (mapOfResponsesAndUsers.Role_Finance_Two.actionTaken === 'PENDING') {
              label = (
                <span className='d-flex flex-column'>
                 PENDING With Finance2 {mapOfResponsesAndUsers.Role_Finance_Two.userFullName && ':'} <strong>{mapOfResponsesAndUsers.Role_Finance_Two.userFullName}</strong>
                </span>
              );
            } else {
              label = (
                <span className='d-flex flex-column'>
                 REJECTED BY Finance2 {mapOfResponsesAndUsers.Role_Finance_Two.userFullName && ':'} <strong>{mapOfResponsesAndUsers.Role_Finance_Two.userFullName}</strong>
                </span>
              );
            }
          }
          return (
            <div
              key={index}
              className={`step-circle ${index + 1 === currentStep ? 'active' : ''}`}
            >
              <span className="step-label">{label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StepProgress;
